import { useState, useEffect, FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { BottomSheet, useBreakpoint } from '@hh.ru/magritte-ui';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Modal from 'bloko/blocks/modal';

import translation from 'src/components/translation';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

import EmptyVacancy from 'src/components/InviteToVideoCall/EmptyVacancy';
import Info from 'src/components/InviteToVideoCall/Info';
import Invitation from 'src/components/InviteToVideoCall/Invitation';
import InviteForm from 'src/components/InviteToVideoCall/InviteForm';
import useVideoCallData from 'src/components/InviteToVideoCall/hooks/useVideoCallData';
import { Applicant } from 'src/components/InviteToVideoCall/types';

import styles from './index.less';

interface Props {
    resumeStateUpdate: (collection: EmployerStateType, callTime: string) => void;
    applicant: Applicant;
    topicId?: string;
    onClose: () => void;
}

const InviteToVideoCall: FC<Props> = ({ onClose, applicant, resumeStateUpdate, topicId }) => {
    const [step, setStep] = useState('loading');
    const { isMobile } = useBreakpoint();
    const { isEmptyVacancyError, formData, ...videoCallData } = useVideoCallData(applicant.resumeHash);

    useEffect(() => {
        if (isEmptyVacancyError) {
            setStep('emptyVacancyError');
        }
        if (videoCallData.vacancy.list.length) {
            setStep('first');
        }
    }, [isEmptyVacancyError, videoCallData.vacancy.list]);

    const nextStep = () => {
        setStep('second');
        Analytics.sendHHEventButtonClick('video_call_search_next_step');
    };

    const prevStep = () => {
        setStep('first');
        Analytics.sendHHEventButtonClick('video_call_search_prev_step');
    };

    let content;
    switch (step) {
        case 'loading':
            content = <Loading scale={LoadingScale.Small} />;
            break;
        case 'first':
            content = (
                <div className={styles.layout}>
                    <InviteForm {...videoCallData} onClose={onClose} nextStep={nextStep} />
                </div>
            );
            break;
        case 'second':
            content = (
                <>
                    <div className={styles.layout}>
                        <Invitation
                            prevStep={prevStep}
                            applicant={applicant}
                            formData={formData}
                            onClose={onClose}
                            resumeStateUpdate={resumeStateUpdate}
                            topicId={topicId}
                        />
                    </div>
                    <Info />
                </>
            );
            break;
        case 'emptyVacancyError':
            content = <EmptyVacancy onClose={onClose} />;
            break;
    }

    const Wrapper = isMobile ? BottomSheet : Modal;

    return (
        <Wrapper visible={true} onClose={onClose}>
            {content}
        </Wrapper>
    );
};

export default translation(InviteToVideoCall);
