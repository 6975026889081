import { ComponentType, FC, PropsWithChildren, ReactNode, RefObject, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { VSpacing } from '@hh.ru/magritte-ui';

import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import defaultError from 'src/components/Notifications/DefaultError';
import { useNotification } from 'src/components/Notifications/Provider';
import { ResumeCommentDeleteMessage } from 'src/components/Notifications/ResumeComments';
import { useSelector } from 'src/hooks/useSelector';
import { addCommentToUser, deleteCommentFromUser } from 'src/models/employer/resume/commentsByUser';
import { addComment, editComment, ResumeComment, Status } from 'src/models/employer/resume/resumeComments';
import { fetcher } from 'src/utils/fetcher';

import Add from 'src/components/ResumeComments/Add';
import { CommentProps } from 'src/components/ResumeComments/Item';
import List from 'src/components/ResumeComments/List';
import useFetchComments from 'src/components/ResumeComments/hooks/useFetchComments';
import { CommentsOwner } from 'src/components/ResumeComments/types';

interface ResumeCommentsProps {
    user: CommentsOwner;
    visible?: boolean;
    addFromCard?: boolean;
    noCommentsCallback?: () => void;
    render?: {
        add?: () => ReactNode;
        item?: ComponentType<CommentProps>;
    };
    topicId?: string;
    vacancyId?: string;
    resumeHash?: string;
    isResumePage?: boolean;
    hasAddButton?: boolean;
    commentsFormRef?: RefObject<HTMLDivElement>;
}

const ResumeComments: FC<ResumeCommentsProps & PropsWithChildren> = ({
    visible = true,
    addFromCard,
    noCommentsCallback,
    user,
    render = {},
    topicId,
    vacancyId,
    resumeHash,
    isResumePage = false,
    hasAddButton = true,
    commentsFormRef,
}) => {
    const isMagritteExp = useSelector((state) => state.isMagritteEmployerResumeView);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const { fetch, comments } = useFetchComments(user, noCommentsCallback);

    const [editId, setEditId] = useState<string | number | null>(addFromCard && !comments.items.length ? 'add' : null);
    const [fetchingIds, setFetchingIds] = useState<number[]>([]);

    const add = useCallback(
        (data: ResumeComment) => {
            dispatch([addComment(data), addCommentToUser({ userId: user, commentId: data.id })]);
        },
        [dispatch, user]
    );

    const edit = useCallback(
        (data: ResumeComment) => {
            dispatch(editComment(data));
        },
        [dispatch]
    );

    const commentAction = useCallback(
        (id: number, action: string) => {
            setFetchingIds([...fetchingIds, id]);
            fetcher
                .postFormData(`/employer/applicant/json_comment`, {
                    action,
                    id,
                    applicantId: user,
                })
                .then(() => {
                    setFetchingIds(fetchingIds.filter((fetchingId) => fetchingId !== id));
                    if (action === 'remove') {
                        addNotification(ResumeCommentDeleteMessage, {
                            props: {
                                restoreComment: () => commentAction(id, 'restore'),
                            },
                        });
                        dispatch(deleteCommentFromUser({ userId: user, commentId: id }));
                    }
                    if (action === 'restore') {
                        dispatch(addCommentToUser({ userId: user, commentId: id }));
                    }
                })
                .catch(() => {
                    setFetchingIds(fetchingIds.filter((fetchingId) => fetchingId !== id));
                    addNotification(defaultError);
                });
        },
        [fetchingIds, user, addNotification, dispatch]
    );

    const hasComments = !!comments.items.length;

    const addComponentProps = {
        editId,
        setEditId,
        user,
        hide: comments.status === Status.Fetching && !hasComments,
        onAdd: add,
        addFromCard,
        topicId,
        vacancyId,
        resumeHash,
        inline: addFromCard,
    };
    const addComponent = render.add ? render.add() : <Add {...addComponentProps} />;

    if (!visible) {
        return null;
    }

    const isOldResume = isResumePage && !isMagritteExp;

    return (
        <MagritteWrapper isEnabled={!isOldResume}>
            {addFromCard && <VSpacing default={12} />}
            {hasAddButton && addComponent}
            {commentsFormRef && <div ref={commentsFormRef} />}
            {comments.items && (
                <List
                    fetchingIds={fetchingIds}
                    editId={editId}
                    setEditId={setEditId}
                    onEdit={edit}
                    commentAction={commentAction}
                    user={user}
                    comments={comments}
                    fetch={fetch}
                    BodyComponent={render.item}
                    resumeHash={resumeHash}
                    topicId={topicId}
                    vacancyId={vacancyId}
                />
            )}
        </MagritteWrapper>
    );
};

export default ResumeComments;
